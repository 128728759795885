
import { UltravoxSession } from 'ultravox-client';
import { api } from '../lib/client';

const backend = new URL(process.env.REACT_APP_BACKEND_SERVER);

let session;

async function functionHandler(message, functions, keys, onMessage) {

  const replaceParameters = (str, fcn) => {
    let result = str;
    let left = {};
    Object.keys(fcn.input).forEach(key => {
      console.log({ key, includes: result.includes(`{${key}}`), str }, 'key');
      if (result.includes(`{${key}}`)) {
        result = result.replace(`{${key}}`, `${fcn.input[key]}`);
      }
      else {
        left[key] = fcn.input[key];
      }
    });
    console.log({ result, left, str, fcn }, 'replaceParameters');
    return { result, left };
  };

  if (message.function_calls) {
    let function_results = await Promise.all(
      message.function_calls?.map(async fn => {
        let f = functions.find(entry => entry.name === fn.name);
        let result, error;
        if (f && f.implementation === 'stub') {
          ({ result } = replaceParameters(f.result, fn));
          return { ...fn, result };
        }
        else if (f && f.implementation === 'rest') {
          const authType = {
            basic: 'Basic',
            bearer: 'Bearer',
          };
          let key = f.key && keys.find(entry => entry.name === f.key);
          let authHeader = key && key.in && authType[key.in] && {
            Authorization: `${authType[key.in]} ${key.value}`
          };
          console.log({ authHeader, key, keys, key: f.key }, 'authHeader');

          try {
            let { result: replaced, left } = replaceParameters(f.url, fn);
            let url, data;
            if (f.method?.toUpperCase() === 'POST') {
              url = new URL(replaced);
              data = left;
            }
            else {
              let params = new URLSearchParams(left);
              url = new URL(replaced + (params.toString() ? `?${params.toString()}` : ''));
            }
            console.log({ url, data }, 'url after construction');

            onMessage && onMessage({
              rest_callout: {
                url: url.toString(),
                method: f.method?.toUpperCase(),
                body: f.method === 'post' ? fn.input : '',
                headers: authHeader
              },
            });
            let response;
            response = await api.post('/httpRequest', {
              request: {
                url,
                method: f?.method || 'get',
                data,
                headers: authHeader,
              }
            });
            console.log({ response }, 'response');
            result = JSON.stringify(response.data, null, 2);
          }
          catch (e) {
            result = e.message;
            console.error(e, 'thing');
            error = JSON.stringify(e);
            return { ...fn, result: JSON.stringify(result, null, 2), error };
          }
        }


        if (f.implementation === 'rest')
          return { ...fn, result };
      }));
    return { function_results, call_id: message.call_id };
  }
};

const transformFunctions = (functions) => functions?.map?.(({ name, description, parameters, url, implementation, method, key }) => {
  return {
    name,
    description,
    input_schema: {
      type: "object",
      url,
      implementation,
      method,
      key,
      properties:
        parameters?.reduce((o, p) => ({
          ...o, [p.name]: {
            type: p.type,
            in: (url.includes(`{${p.name}}`)? 'path' : (method === 'post' ? 'body' : 'query')),
            description: p.description
          }
        }), {}) || {},
    }
  };
});


export async function createAgent({ modelName, prompt, options, functions, keys, onClose, onMessage, audioModel, voice }) {
  console.log({ modelName, prompt, options, functions, keys, onClose, onMessage, audioModel }, 'createAgent');
  let { data } = await api.post('/agents', { modelName, prompt, options, functions: transformFunctions(functions) });
  if (data?.socket) {
    if (!audioModel) {
      let wsPath = `${backend.protocol === 'https:' ? 'wss:' : 'ws:'}//${backend.host}${data?.socket}`;
      let ws = new WebSocket(wsPath);
      console.log({ wsPath, ws }, 'wsPath');
      ws.addEventListener('message', async (message) => {
        try {
          data = JSON.parse(message.data);
          onMessage && onMessage(data);
          if (data.function_calls) {
            let response = await functionHandler(data, functions, keys, onMessage);
            response?.call_id && ws.send(JSON.stringify(response));
            onMessage && onMessage(response);
          }
        }
        catch (e) {
        }
      });
      ws.addEventListener('error', (err) => {
      });
      ws.addEventListener('close', (err) => {
        onClose && onClose(err.message);
      });
      data.ws = ws;
    }
    else {
      const debugMessages = new Set(["debug"]);
      // sparse array of function calls
      let calls = [];
      let transcriptLen = 0;
      session = new UltravoxSession({ experimentalMessages: debugMessages });
      let state = session.joinCall(data.socket);
      ['ultravoxSessionStatusChanged', 'ultravoxTranscriptsChanged', 'ultravoxExperimentalMessage']
        .forEach(event => state.addEventListener(
          event,
          (msg) => {
            let { transcripts, message } = msg;
            let call;
            console.log({ transcripts, msg, calls, transcriptLen }, 'transcripts');
            let debug = message && message.type && message.message; 
            if (debug && debug.startsWith('LLM response: \nTool calls:')) {
              let [[, method, body]] = debug.matchAll(/FunctionCall\(name='([^']*)'.*args='([^']*)'.*\)/ig).toArray() || [[]];
              call = { rest_callout: { method, body, url:"" } };
            }
            else if (debug && debug.startsWith('Tool call complete. Result: ')) {
              let [[, body, name]] = debug.matchAll(/.*role: MESSAGE_ROLE_TOOL_RESULT[\s\S]*text: "(.*)"[\s\S]*tool_name: "(.*)"/ig).toArray() || [[]];
              call = { function_results: [{name, input: [], result: body.replace(/\\/g,'') }] };
            }
            call && (calls[transcriptLen] = (calls[transcriptLen] || [])) && calls[transcriptLen].push(call);
            transcripts && onMessage && onMessage(transcripts.map((t, ind) => ([{ [t.speaker]: t.text }, ...(calls[ind] || [])])).flat());
            transcriptLen = (transcripts && transcripts?.length - 1) || transcriptLen;
          }
        ));
      data.ws = true;

    }
  }

  return data;
}

export async function listModels() {
  let { data } = await api.get('/models');
  console.log({ data }, 'models');
  return Object.entries(data);
}

export async function updateAgent({ id, prompt, options, functions, keys }) {
  let { data } = await api.put(`/agents/${id}`, { prompt, options, functions: transformFunctions(functions) });
  return data;
}

export async function deleteAgent({ id }) {
  try {
    console.log({ id, session }, 'deleteAgent');
    if (session) {
      session.leaveCall();
      session = null;
    }
    let { data } = await api.delete(`/agents/${id}`);
    return data;
  }
  catch (e) {
    // we may be trying to delete an agent because of a failed network, don't care too much
  }
}

export async function listVoices() {
  let { data } = await api.get('/voices');
  return data;
}